var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-card",
          { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: { title: "사업장", value: _vm.plantName },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: { title: "자재명", value: _vm.materialName },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: { title: "자재코드", value: _vm.materialCd },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "화학자재일련번호",
                      value: _vm.param.mdmChemMaterialId,
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical",
      },
      [
        _c("c-tab", {
          attrs: {
            type: "vertical",
            tabItems: _vm.tabItems,
            height: _vm.tabheight,
          },
          on: {
            "update:height": function ($event) {
              _vm.tabheight = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (tab) {
                return [
                  _c(tab.component, {
                    tag: "component",
                    attrs: { param: _vm.param },
                    on: {
                      "update:param": function ($event) {
                        _vm.param = $event
                      },
                      saveChemComponents: _vm.saveChemComponents,
                    },
                  }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }