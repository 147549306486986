<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <c-label-text title="사업장" :value="plantName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="자재명" :value="materialName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="자재코드" :value="materialCd"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="화학자재일련번호" :value="param.mdmChemMaterialId"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
      <c-tab
        type="vertical"
        :tabItems="tabItems"
        :height.sync="tabheight"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :param.sync="param"
            @saveChemComponents="saveChemComponents"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import { uid } from 'quasar';
export default {
  name: 'chem-msds',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        plantCd: '',
        plantName: '',
        materialCd: '',
        materialName: '',
        isSaved: '',
      }),
    },
  },
  data() {
    return {
      plantCd: '',
      plantName: '',
      materialCd: '',
      materialName: '',
      editable: true,
      tabheight: '',
      cheminfo: {
        col1: '사업장1',
        col2: 'CM-00000001',
        col3: '가성소다',
      },
      infoUrl: '',
      tab: 'chemComponents',
      tabItems: [
        { name: 'chemComponents', icon: 'grid_view', label: '조성품', component: () => import(`${'./chemComponents.vue'}`) },
        { name: 'chemLaw', icon: 'gavel', label: '규제사항', component: () => import(`${'./chemLaw.vue'}`) },
        { name: 'chemLicense', icon: 'assignment_turned_in', label: '관련 인허가', component: () => import(`${'./chemLicense.vue'}`) },
        { name: 'chemMsdsFile', icon: 'drive_folder_upload', label: 'MSDS', component: () => import(`${'./chemMsdsFile.vue'}`) },
        { name: 'chemDanger', icon: 'warning', label: '위험물 및 지정수량', component: () => import(`${'./chemDanger.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.infoUrl = selectConfig.mdm.mam.chem.get.url;
      this.tabheight = (window.innerHeight - 200) + 'px'
      this.getDetail();
    },
    getDetail() {
      if (this.param.mdmChemMaterialId) {
        this.$http.url = this.$format(this.infoUrl, this.param.mdmChemMaterialId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.plantCd = _result.data.plantCd;
          this.plantName = _result.data.plantName;
          this.materialCd = _result.data.materialCd;
          this.materialName = _result.data.materialName;
        },);
      }
    },
    saveChemComponents() {
      this.$set(this.param, 'isSaved', uid())
    }
  }
};
</script>